<template>
 <div class="accFeed__wrapper matchmaking__wrapper">
    <div class="accFeed__sidebar matchmaking__sidebar">
      <div class="accFeed__box matchmaking__box">
        <span
          class="btn btn-primary w-100"
          @click="$router.push(`/profile/matchmaking/${isBuyer ? 'buyer' : 'seller'}`)">
          Back to Projects
        </span>
      </div>
    </div>
    <div class="project-details" v-if="!isLoading">
      <BModal
        data-id="modal-request-project"
        ref="modalEditProject"
        id="modalEditProject"
        class="modal-request-offer"
        size="lg"
        hide-header
        hide-footer>
        <div class="body">
          <FormRequest v-if="project && project.marketplace"
          :marketplaceId="project.marketplace.id"
          :fields="project.data"
          :countries="project.marketplace.countries"
          :formId="String(project.id)"
          :isNewProject="false"
          :project="project"
          @onUpdateProject="onUpdateProject"
          @onDeleteProject="onDeleteProject"
          @close="closeModal"
          >
          </FormRequest>
        </div>
      </BModal>
      <BModal
        data-id="modal-request-offer"
        ref="modalRequestOffer"
        if="modalRequestOffer"
        class="modal-request-offer"
        size="xl"
        hide-header
        hide-footer>
        <div class="body">
          <OfferFormRequest v-if="project && project.marketplace"
          :marketplaceId="project.marketplace.id"
          :formId="formId"
          :isNewOffer="true"
          :projectId="project.id"
          @onCreatedOffer="onCreatedOffer"
          @close="closeModal"
          />
        </div>
      </BModal>
      <div class="project-details__wrapper">
        <div class="project-details__header">
          <h1 class="project-details__title">
            {{ project.name }}
          </h1>
          <a v-if="isBuyer"
            @click="openEditProjectModal"
            class="project-details__action edit">
            <i class="icon-edit-project"></i>
            Edit Project
          </a>
          <a v-else>
            <span v-if="project.saved" class="status saved">
              Saved
            </span>
          </a>
        </div>
        <span class="project-details__deadline">
          Offer deadline: <strong>{{ formattedDeadline(project.deadline_at) }}</strong>.
          <br>Allow to make offers:
          <strong> {{ project.offers_allow === 1 ? 'Yes' : 'No' }} </strong>
        </span>
        <section class="make-offer-content">
          <div class="form-col form-col-2">
            <div class="form-col-1">
              <label>Company Name</label>
              <span class="field">
                <span v-if="isBuyer || me.safe_seller">
                  {{ project.company_name }}
                </span>
                <span v-else>
                  ********
                </span>
              </span>
            </div>
            <div class="form-col-1">
              <label>Project Country</label>
              <span class="field" v-if="project.company_country && project.company_country.name">
                {{ project.company_country.name }}
              </span>
            </div>
          </div>
          <div class="form-col-1 mb-2">
            <label>Project location</label>
            <span class="field">
              <span v-if="isBuyer || me.safe_seller">
                {{ project.company_address}}
              </span>
              <span v-else>
                ********
              </span>
            </span>
          </div>
          <div v-if="filteredTextAndDropboxFields" class="form-col form-col-2">
            <div v-for="(data, index) in filteredTextAndDropboxFields"
            :key="index" class="form-col-1 mb-2">
              <div v-if="data.type === 'dropbox'">
                <div v-if="data.multiple">
                  <label>{{data.name}}</label>
                  <span class="field">
                    <span v-if="Array.isArray(data.initValue)">
                      {{ data.initValue.map((value) => data.values[+value]).join(', ') }}
                    </span>
                    <span v-else>
                      {{ data.values[+data.initValue] }}
                    </span>
                  </span>
                </div>
                <div v-else>
                  <label>{{data.name}}</label>
                  <span class="field">{{ data.values[data.initValue] }}</span>
                </div>
              </div>
              <div v-else>
                <label>{{data.name}}</label>
                <span class="field">{{data.initValue}}</span>
              </div>
            </div>
          </div>
          <div class="form-col-1 location mb-2"
            v-if="isBuyer || me.safe_seller">
            <label>Location</label>
            <div class="map-wrapper">
              <Map :modelValue="location"/>
            </div>
          </div>
          <div v-if="filteredTextareaFields">
            <div v-for="(data, index) in filteredTextareaFields"
            :key="index" class="form-col-1 mb-2">
              <label>{{data.name}}</label>
              <span class="field">{{data.initValue}}</span>
            </div>
          </div>
          <div class="addmedia" v-if="documents.length || images.length || video.length">
            <BModal v-model="showPreview"
            hide-footer
            hide-header
            size="lg"
            @close="hidePreviewModal">
              <div class="modal-image">
                <div class="modal-image-content">
                  <span class="close" @click="hidePreviewModal">&times;</span>
                  <img :src="selectedImage" alt="" style="max-width: 80%;"
                    v-if="selectedImage">
                  <iframe :src="selectedPdf" frameborder="0" style="height: 80vh; width: 100%;"
                    v-else/>
                </div>
              </div>
            </BModal>
            <div class="addmedia__downloaded">
              <h5 class="addmedia__downloaded-title" style="font-weight: 600;">
                Presentation and documentation
              </h5>
              <div v-if="documents.length">
                <h6>Documents ({{ documents.length }}):</h6>
                <div class="addmedia__downloaded-item"
                  v-for="item in documents" :key="item.url">
                    <span class="icon-pdf"></span>
                    <span class="pointer"
                    @click="showPreviewModal(item.url, 'pdf')">
                      {{ item.title }}
                    </span>
                  </div>
              </div>
              <div v-if="images.length">
                <h6>Images ({{ images.length }}):</h6>
                <ul class="media-list">
                  <li class="media-item" v-for="item in images" :key="item.url">
                    <img :src="item.url" alt="" class="pointer"
                    @click="showPreviewModal(item.url, 'img')">
                    <span>{{ item.title }}</span>
                  </li>
                </ul>
              </div>
              <div v-if="video.length">
                <h6>Video ({{  video.length }}):</h6>
                <ul class="media-list">
                  <li class="media-item" v-for="item in video" :key="item.thumbnail">
                    <div v-html="item.html"></div>
                    <span>{{ item.title }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div v-if="isBuyer">
            <div class="offers" v-if="project.status === 2">
              <h3>
                <strong>Project is closed</strong>
              </h3>
              <div class="accordion offers__box"
                v-for="(offer, index) in offers"
                :key="index">
                <template
                  v-if="offer.status && offer.status.some(status => status.name === 'Approved'
                  && status.active === true)">
                  <OfferCard
                      :type="offer.status &&
                          offer.status.filter(status => status.active === true).length > 0 ?
                          String(offer.status.filter(status => status.active === true)[0].name).
                          toLocaleLowerCase() : null"
                      :offer="offer"
                      :index="index"
                      @changeStatus="changeStatus"
                      @sendMessage="sendMessage"
                  />
                </template>
              </div>
            </div>
            <div class="offers" v-else-if="project.offers_count">
              <h3 v-if="project.offers_count > 1">
                <strong>{{ project.offers_count }}</strong>
                offers from Sellers
              </h3>
              <h3 v-else>
                <strong>{{ project.offers_count }}</strong>
                offer from a Seller
              </h3>
              <div class="accordion offers__box"
                v-for="(offer, index) in offers" :key="index">
                <OfferCard
                  :type="offer.status &&
                  offer.status.filter(status => status.active === true).length > 0 ?
                  String(offer.status.filter(status => status.active === true)[0].name)
                  .toLocaleLowerCase() : null"
                  :offer="offer"
                  :index="index"
                  @changeStatus="changeStatus"
                  @sendMessage="sendMessage"
                />
              </div>
            </div>
          </div>
          <div class="make-offer-bottom" v-if="!isBuyer">
            <div class="make-offer-action">
              <button type="submit"
                v-if="!project.saved"
                @click="sellerSaveProject(project.id)"
                class="btn btn-primary">
                Save
              </button>
              <button type="submit"
                v-else
                @click="sellerUnsaveProject(project.id)"
                class="btn btn-primary">
                Unsave
              </button>
              <button
                v-if="project.offers_allow === 1 && project.status !== 2"
                class="btn btn-primary"
                @click="openMakeOfferModal">
                Make an Offer
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
    <div v-else class="d-flex justify-content-center">
      <div class="loader"></div>
    </div>
  </div>
</template>

<script>
import exception from '@/mixins/exception';
import MatchmakingService from '@/services/MatchmakingService';
import Map from '@/components/Map.vue';
import axios from 'axios';
import { BModal } from 'bootstrap-vue';
import FormRequest from '@/components/matchmaking/ProjectFormRequest.vue';
import OfferFormRequest from '@/components/matchmaking/OfferFormRequest.vue';
import OfferCard from '@/views/profile/Matchmaking/Seller/OfferCard.vue';

export default {
  name: 'Project',
  mixins: [exception],
  components: {
    Map, BModal, FormRequest, OfferFormRequest, OfferCard,
  },
  data() {
    return {
      token: process.env.VUE_APP_MAPBOX_TOKEN,
      location: {
        lng: 14.3001435907641,
        lat: 47.6077978292689,
        bearing: 0,
        pitch: 0,
        zoom: 8,
      },
      projectSlug: this.$route.params.id,
      project: {},
      offers: [],
      selectedImage: null,
      selectedPdf: null,
      showPreview: false,
      isLoading: true,
      formId: '',
    };
  },
  methods: {
    async changeStatus(status) {
      await this.getOffers();
      if (status === 3) {
        this.project.status = 2;
      }
    },
    async sellerSaveProject(projectId) {
      try {
        await MatchmakingService.sellerSaveProject({ project_id: projectId });
        this.project.saved = true;
      } catch (error) {
        this.handleException(error);
      }
    },
    async sellerUnsaveProject(projectId) {
      try {
        await MatchmakingService.sellerUnsaveProject({ project_id: projectId });
        this.project.saved = false;
      } catch (error) {
        this.handleException(error);
      }
    },
    async getProject() {
      try {
        this.$store.commit('SET_IS_LOADING', true);
        this.isLoading = true;
        const { data } = await MatchmakingService
          .getMarketplaceProject(this.projectSlug, this.isBuyer);
        this.project = data;
        this.getCoordinates();
      } catch (error) {
        this.handleException(error);
      } finally {
        this.$store.commit('SET_IS_LOADING', false);
        this.isLoading = false;
      }
    },
    async getCoordinates() {
      if (!this.isBuyer && !this.me.safe_seller) {
        return;
      }
      try {
        const response = await axios.get(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(this.project.company_address)}.json?access_token=${this.token}`,
        );
        const [lng, lat] = response.data.features[0].center;
        this.location.lng = lng;
        this.location.lat = lat;
      } catch (error) {
        console.error('Wrong address');
      }
    },
    async onUpdateProject() {
      await this.getProject();
      this.closeModal();
    },
    async onDeleteProject(projectId) {
      try {
        this.isLoading = true;
        await MatchmakingService.deleteMarketplaceProject(projectId);
        this.$router.push('/profile/matchmaking');
      } catch (error) {
        this.handleException(error);
      } finally {
        this.isLoading = false;
      }
    },
    async sendMessage(messageData) {
      const { offerId, text } = messageData;
      if (text) {
        try {
          const payload = {
            offer_id: offerId,
            text,
          };
          const { data } = await MatchmakingService.marketplaceOfferMessage(payload);
          this.offers.forEach((el) => {
            if (el.id === Number(offerId)) {
              el.messages.push(data);
              console.log(el.messages, ' el.messages');
            }
          });
        } catch (error) {
          this.handleException(error);
        }
      }
    },
    async onCreatedOffer(data) {
      console.log(data, ' data');
      this.$router.push('/profile/matchmaking/seller/offers');
    },
    async getOffers() {
      try {
        const { data } = await MatchmakingService.projectOffers(this.project.id, this.isBuyer);
        console.log(data, ' data');
        this.offers = data;
      } catch (error) {
        this.handleException(error);
      }
    },
    openMakeOfferModal() {
      this.$refs.modalRequestOffer.show();
      this.generateFormId();
    },
    generateFormId() {
      this.formId = Math.random().toString(36).substring(2, 15)
        + Math.random().toString(36).substring(2, 15);
    },
    showPreviewModal(url, type) {
      if (type === 'img') {
        this.selectedImage = url;
        this.showPreview = true;
      } else {
        this.selectedPdf = url;
        this.showPreview = true;
      }
    },
    hidePreviewModal() {
      if (this.selectedImage) {
        this.selectedImage = null;
        this.showPreview = false;
      } else {
        this.selectedPdf = null;
        this.showPreview = false;
      }
    },
    openEditProjectModal() {
      this.$refs.modalEditProject.show();
    },
    closeModal() {
      this.$refs.modalEditProject.hide();
      this.$refs.modalRequestOffer.hide();
      this.formId = '';
    },
  },
  computed: {
    me() {
      return this.$store.getters.getMe;
    },
    filteredTextareaFields() {
      if (this.project.data) {
        return this.project.data.filter((field) => field.type === 'textarea');
      }
      return [];
    },
    filteredTextAndDropboxFields() {
      if (this.project.data) {
        return this.project.data.filter((field) => field.type !== 'textarea');
      }
      return [];
    },
    images() {
      if (this.project.documents) {
        return this.project.documents.filter((doc) => !doc.url.includes('pdf'));
      }
      return [];
    },
    documents() {
      if (this.project.documents) {
        return this.project.documents.filter((doc) => doc.url.includes('pdf'));
      }
      return [];
    },
    video() {
      if (this.project.video) {
        return this.project.video;
      }
      return [];
    },
    isBuyer() {
      return this.$route.params.type === 'buyer';
    },
    formattedDeadline() {
      return (deadline) => {
        if (deadline === '∞') {
          return 'not specified';
        }
        return deadline;
      };
    },
  },
  async mounted() {
    await this.getProject();
    await this.getOffers();
  },
};
</script>

<style lang="scss" scoped>
.make-offer-bottom {
  margin: 30px 0 10px 0;
  display: flex;
  justify-content: flex-end;
}
.modal-image {
  position: fixed;
  text-align: center;
  z-index: 1111111;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(0, 0, 0, 0.9);

  &-content {
    margin: 5% auto;
    width: 80%;
  }

  .close {
    color: #fff;
    float: right;
    font-size: 28px;
    font-weight: bold;
    cursor: pointer;
  }
}

</style>
